.container {
  margin-top: 50px;
}
.actionsContainer {
    display: flex;
    position: relative;
    margin-top: 14px;
    .action {
      border-radius: 3px;
      background-color: #708fa0;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:first-child {
        margin-right: 2px;
      }
      &:hover {
        background: #628394;
      }
    }
  
}
.moreBtn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 16px 9px;
  
    &.active {
      background-color: #708fa0;
      i {
        color: #fff;
      }
    }
  
    i {
      color: #000000;
    }
}
  
.datePickerWrapper {
  input {
    width: 81px;
    height: 35px;
    box-shadow: none;
    display: block;
    padding: 6px 12px;
    color: #555555;
    font-size: 14px;
    font-family: 'Open sans', sans-serif;
    background-color: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border: 2px solid #6fdbe8;
    }
    &.input_border_error {
      border: 2px solid #ff8989;
    }
    &:disabled {
      background-color: #eeeeee;
      color: #eeeeee;
    }
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0;
  }
  ::-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 19+ */
  :-moz-placeholder {
    color: #c0c0c0;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    color: #c0c0c0;
  }
}

.monthSelectionContainer {
  display: flex;
  gap: 10px;
  margin-top: 14px;
} 