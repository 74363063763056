@import 'resources/styles/mixins.scss';

.dropdown {
  width: 400px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  text-transform: none;

  @include media-mobile {
    max-width: calc(100vw - 70px);
  }
}

.searchResults {
  border-top: 1px solid #e5e5e5;

  max-height: 343px;
  overflow: auto;
  @include media-mobile {
    max-height: calc(100vh - 350px);
  }
  &Loader {
    margin-top: 20px;
  }
}

.globalPublicGroupWrapper {
  border-top: 5px solid #265a8c;
}

.publicGroupLogo {
  width: 25px;
  height: 25px;
}

.input {
  margin-top: -1px;
  margin-bottom: -2px;

  &Wrapper {
    position: relative;

    &Reset {
      position: absolute;
      right: 45px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.createButton {
  width: 100%;

  &.createButtonAwoWW {
    background-color: #e2001A;
  }

  &Container {
    display: flex;
    justify-content: flex-start;
  }

  @include media-tablet {
    width: auto;
  }
}

.favoriteButtonWrapper {
  display: flex;

  @include media-tablet {
    width: auto;
  }
}

.messageWarning {
  padding: 10px;
  border-bottom: 1px solid #eee;
  position: relative;
  border-left: 3px solid white;
  font-size: 12px;
  margin: 3px 0;
  text-align: left;
  font-weight: 400;

  &:hover {
    border-left: 3px solid #6fdbe8;
  }
}

.memberIcon {
  font-size: 16px;
  color: #bfbfbf;
  position: relative;
  left: -2px;

  &Wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
  }
}
