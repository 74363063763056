@import 'resources/styles/mixins/table.scss';
@import 'resources/styles/mixins.scss';
@include table;

.email {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.titleCheckbox {
  margin: 0 0 0 6px;
}

.titleCheckboxTitle {
  min-width: 10px;
}

.deletionButtons,
.actionButtons {
  display: flex;
  justify-content: space-between;
}

.userItemContainer {
  display: flex;
  align-items: center;
}

.moveUserGroupContainer {
  h3 {
    margin-top: 0;
  }
  .requiredIndicator::after {
    content: '*';
    color: red;
    margin-left: 5px;
  }
  .kitaSelection {
    margin-bottom: 10px;
  }
  .moveGroups {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    .moveUser {
      gap: 10px;
      margin-left: 20px;
      .kidName {
        min-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btnRemoveUser {
        cursor: pointer;
      }
      .moveGroupSelect {
        margin-left: auto;
      }
    }
  }
  .moveUserError {
    color: red;
  }
}

.titleButtons {
  @include media-mobile {
    position: initial;
  }
}

.table {
  &Wrapper {
    overflow: hidden;

    @include media-mobile {
      overflow-x: scroll;
    }
  }

  td.headerText {
    max-width: 110px;
    font-size: 12px;
  }

  .avatarContainer {
    position: relative;
  }

  .awoMember {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16px;
    height: 16px;

    img {
      width: 100%;
    }
  }
}

.dropdownContainer {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.longTextButtons {
  display: inline-block;
  max-width: 200px;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  overflow-wrap: anywhere;
  padding: 5px 10px;
}

.confirmBody {
  text-align: center;
  font-size: 13px;
  padding: 21px;
}
