.serverCheckPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
}

.bannerImage {
  max-width: 80%;
  width: 334px;
  height: 290px;
  margin-bottom: 20px;
}

.description {
  width: 600px;
  max-width: 80%;
  font-size: 20px;
  color: #333;
  line-height: 1.5;
}
