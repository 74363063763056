.datePickerContainer {
    gap: 20px;
    display: inline-flex;
    flex-flow: row;

    .halfDayCheckbox {
      margin-left: 0;
      margin-right: 0;
    }

    input {
      width: 100%;
      height: 35px;
      box-shadow: none;
      display: block;
      padding: 6px 12px;
      color: #555555;
      font-size: 14px;
      font-family: 'Open sans', sans-serif;
      background-color: #fff;
      border: 2px solid #ededed;
      border-radius: 4px;
      transition: border-color ease-in-out 0.15s;

      &:focus {
        border: 2px solid #6fdbe8;
      }
      &.input_border_error {
        border: 2px solid #ff8989;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #eeeeee;
      }
    }
    ::-webkit-input-placeholder {
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 19+ */
    :-moz-placeholder {
      color: #c0c0c0;
    } /* Firefox 18- */
    :-ms-input-placeholder {
      color: #c0c0c0;
    }
}
.container {
    padding: 0px 20px;
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}