@import 'resources/styles/mixins.scss';

.container {
  position: relative;
}

.label {
  margin-left: 3px;

  &s {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }
}

.question {
  margin-bottom: 34px;
  font-size: 13px;
}

.answer {
  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &Container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  &Checkbox {
    width: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &Radio {
    position: relative;
    top: -8px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  &Text {
    font-size: 13px;

    &Container {
      max-width: 350px;
      flex-grow: 1;
      margin: 0 29px 15px 1px;
    }
  }

  &Progress {
    height: 10px;
    box-shadow: none;
    background: #ededed;
    border-radius: 10px;
    overflow: hidden;
    min-width: 30%;

    &Value {
      height: 100%;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-align: center;
      background-color: #6fdbe8;
      transition: width 0.6s ease;
    }
  }

  &Votes {
    margin-top: 19px;
    font-size: 13px;
    color: #555555;

    &Clickable {
      color: #6fdbe8;
      cursor: pointer;
    }
  }
}

.voteButton {
  margin: 22px 0 7px;
}

.hiddenNotify {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #555;
  background-color: #f7f7f7;
  border-color: #ededed;
  font-size: 13px;
  margin-top: 17px;

  &Margin {
    margin-bottom: 7px;
  }
}

.addSuggestion {
  &Wrapper {
    display: block;
    width: 100%;
  }
  &Container {
    position: relative;
    margin: 10px 0;
    width: 100%;
  }

  &Input {
    padding-right: 70px !important;
  }

  &Button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
  }
}

.error {
  color: #ff8989;
  margin-bottom: 10px;
}

.suggestionControl {
  &s {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
      height: 28px;
    }
  }
  &Image {
    width: 27px;
    height: 27px;
    overflow: hidden;
    border-radius: 3px;
  }
  &Button {
    padding: 4px 8px;
    font-size: 15px;
    margin-left: 10px;
  }
}
@include media-mobile {
  .answer {
    &Checkbox {
      width: 40px;

      &:not(.answerRadio) {
        margin-left: -17px;
      }
    }

    &Radio {
      left: 0;
    }

    &Wrapper {
      flex-wrap: nowrap;
    }

    &Container {
      flex-wrap: nowrap;
      align-items: center;  
    }

    &TextContainer {
      max-width: none;
      width: auto;
      flex-grow: 1;
      //display: inline-flex;
      align-items: center;
    }

    .answerProgress {
      flex-grow: 1;
      min-width: 30%;
    }
  }
}

@media screen and (max-width: 555px) {
  .answerVotes {
    margin: 12px 0 11px;
  }
}

.hiddenGreen {
  background-color: #bce5bc;
  max-width: 350px;
  flex-grow: 1;
  margin: 0 29px 15px 1px;
  padding: 6px;
  border-radius: 10px;
}

.answerTextContainer {
  padding: 6px;
}
