@import 'resources/styles/mixins.scss';

.panel {
  background-color: #ffffff;
  margin-bottom: 15px;
  &Top {
    border: 3px solid #fff;
    border-radius: 3px;
    user-select: none;

    &Banner {
      position: relative;
      width: 100%;
      height: 100%;
      height: 110px;

      .uploadBanner {
        position: absolute;
        right: 5px;
        bottom: 5px;
        display: none;
        z-index: 2;
      }
      &:hover {
        .uploadBanner {
          display: block;
        }
      }

      &WithImage {
        height: 192px;

        @include media-laptop {
          height: 158px;
        }

        @include media-tablet {
          height: 121px;
        }
      }
      &Fade {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        pointer-events: none;

        &:not(:hover) > * {
          display: none;
        }

        &:after {
          content: '';
          width: 100%;
          height: calc(100% + 20px);
          position: absolute;
          top: -20px;
          left: 0;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0,
            rgba(0, 0, 0, 0) 1%,
            rgba(0, 0, 0, 0.38) 100%
          );
        }
      }
    }
  }
}

.favoriteIconWrapper {
  position: absolute;
  right: 12px;
  top: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #E2001A;
  cursor: pointer;
}

.groupInfoWrapper {
  padding-left: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  color: #ffffff;
  pointer-events: none;

  @include media-mobile {
    justify-content: center;
  }
}

.textWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;

  @include media-mobile {
    flex-direction: column;
    align-items: start;
    gap: 0;
  }
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  &Thin {
    font-weight: 100;
  }

  @include media-mobile {
    padding-right: 24px;
    display: -webkit-box;
    font-size: 18px;
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.description {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 14px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

  @include media-mobile {
    margin-bottom: 0;
    font-size: 12px;
  }
}

.panelBottom {
  padding: 10px;
  position: relative;

  @include media-mobile {
    padding-bottom: 0;
  }

  &Wrapper {
    padding-left: 160px;
    height: 36px;
    display: flex;
    justify-content: space-between;

    @include media-mobile {
      flex-wrap: wrap;
      height: auto;
      padding-left: 0;
      padding-top: 44px;
    }
    @include media-tablet {
      height: auto;
    }
  }
}

@include media-mobile {
  .buttons,
  .stats {
    margin-bottom: 10px;
  }
}

.buttons {
  flex: 1;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  position: relative;

  &MarginLeft {
    margin-left: 6px;
  }
}

.stats {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  word-break: normal;
  hyphens: auto;
  gap: 10px;
  padding-left: 20px;

  @include media-tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  @include media-mobile {
    min-width: 100%;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
  }

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-tablet {
      display: grid;
      grid-template-columns: 36px 1fr;
    }
    @include media-mobile {
      display: flex;
    }

    &Icon {
      font-size: 22px;
      color: #555555;
    }
    &Count {
      font-weight: 800;
      padding-right: 3px;
    }
    &Divider {
      width: 1px;
      height: 100%;
      background-color: #eee;
    }
    &Link {
      cursor: pointer;
      .statsItemTitle {
        color: #555555;
      }
    }
    &Title {
      font-size: 12px;
    }
  }
  &PostsIcon {
    color: #777;
  }
  &GroupButton {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
}

.noInformationAttention {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 17px;
}

.groupButton {
  margin-right: 5px;
}
