.container {
  margin-bottom: 10px;
}

.exportButtonContainer {
  padding-left: 10px;
  padding-bottom: 10px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 10px;
  border-bottom: 1px solid #eee;
  border-left: 3px solid white;

  &:hover {
    border-left: 3px solid #6fdbe8;
    background-color: #f7f7f7;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &Avatar {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    &Name {
      font-size: 14px;
      font-weight: 500;
      color: #555;
      margin-bottom: 2px;

      &Checkout {
        color: #777;
        font-style: italic;
      }
    }

    &Status {
      display: inline-flex;
      border-radius: 3px;
      padding: 3px;
      margin-top: 4px;
      font-size: 0.75rem;
      color: white;
      font-weight: 500;

      &Checkin {
        background-color: #08803a;
      }

      &Checkout {
        background-color: #ffae00;
      }
      &Vacation {
        background-color: #7f00ff;
      }
      &Sick {
        background-color: #bd081c;
      }
    }
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.authorizedPersonsTitle {
  margin: 24px 48px 8px;
  border-bottom: 1px solid #d2d2d2;
  font-size: 20px;
}

.kidContactInfo {
  padding: 0 48px;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 2fr;

  .parentName {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .avatar {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .contactInfoList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .contactInfoItem {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }
  }
}
