@import 'resources/styles/mixins.scss';

.container {
  width: 390px;
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;

  @include media-tablet {
    width: 100%;
    margin-right: 0;
  }

  .headerContainer {
    margin-left: 5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 5px;

    .createButton {
      background: #257d9d;
      color: #fff !important;
      padding: 4px 8px;
      font-size: 12px;
      margin-right: 10px;
      &.createButtonLoading {
        color: transparent !important;
      }

      @include media-mobile {
        padding: 5px 9px;
        font-size: 13px;
      }

      i {
        margin-right: 5px;

        @include media-mobile {
          margin-right: 0px;
        }
      }
    }

    .deleteButton {
      background: #ededed;
      color: #7a7a7a !important;
      padding: 4px 8px;
      font-size: 12px;

      @include media-mobile {
        padding: 5px 9px;
        font-size: 13px;
      }
    }

    .filterButton {
      background: #ededed;
      color: #7a7a7a !important;
      padding: 4px 8px;
      font-size: 12px;
      position: relative;

      @include media-mobile {
        padding: 5px 9px;
        font-size: 13px;
      }
      &.filterButtonNoEmployee {
        margin-left: 5px;
      }
    }
    .chatBtnWrapper {
      display: flex;
    }
  }
}
.chatSelected {
  @include media-mobile {
    display: none;
  }
}

.popupHeader {
  padding: 40px 20px 10px;
  border-bottom: none;
  text-align: center;
  color: #555;
  font-weight: 300;
  font-size: 20px;
}

.bodyTitle {
  background-color: #f7f7f7;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.emptyContainer {
  padding: 10px;
}

.buttonText {
  @include media-mobile {
    display: none;
  }
}

.tooltip {

  &:after {
    left: 50%;
    right: unset;
  }

  & {
    background-color: #257d9d;
    object-position: right top;
  }

  @media screen and (max-width: 1300px) {
    left: -55px;

    &:after {
      left: unset;
      right: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    left: 50%;

    &:after {
      left: 50%;
      right: unset;
    }
  }

  @media screen and (max-width: 1100px) {
    left: -55px;

    &:after {
      left: unset;
      right: 0;
    }
  }

  @media screen and (max-width: 990px) {
    left: 50%;

    &:after {
      left: 50%;
      right: unset;
    }
  }

  @media screen and (max-width: 885px) {
    left: -55px;

    &:after {
      left: unset;
      right: 0;
    }
  }
}

.tooltipDeleteAll {
  background-color: #257d9d;
}

.buttonsContainer {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .buttonCancel {
    margin-right: 10px;
  }
}

.buttonWithLoader {
  background: #257d9d;
  color: #fff !important;

  &:hover {
    background: #257d9d;
  }
}

.filterUserContainer {
  margin: 10px;
}

.confirmText {
  text-align: center;
  margin: 15px 10px;
  font-weight: 300;
}

.filterActive {
  position: absolute;
  top: -4px;
  right: -2px;
  padding: 3px 5px;
  background-color: #257d9d;
  border-radius: 2px;
  font-family: 'Open sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: white;
  font-size: 10px;
  line-height: 10px;
}

.filterContainer {
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  .buttonWrapper {
    padding: 6px 8px;
    cursor: pointer;
    flex: 1;
    color: white;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
    background-color: #257d9d;
    border-radius: 4px;
    &.active {
      font-weight: bold;
      background-color: #1e5f73;
      color: #fff;
    }
    .unreadWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .unreadBadge {
        background-color: #ff8989;
        color: white;
        padding: 1px 5px;
        border-radius: 3px;
        font-size: 10px;
      }
    }
  }
}
