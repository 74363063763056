.upload {
  height: 36px;
  width: 47px;
  font-size: 14px;
  background-color: #ededed;
  color: #777;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.voiceForPost {
    height: 100%;
  }

  &.isCommentPage {
    width: auto;
  }

  &:hover {
    background: rgba(207, 202, 202, 0.719);
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pulsate {
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(1.2);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .elapsedTime {
    font-size: 12px;
  }
}
